import { IEnumerationItem } from "features/configuration/models/Enumeration"
import { BaseType, DataType } from "features/configuration/models/ExtensionType"

export interface IBillingType {
    id: number,
    code: string,    
    name: string,
    category: string,
    description: string,

    isRegistration: boolean,
    isSchoolFees: boolean,

    isArticle: boolean,

    deliveryArticleId: number,
    articleOption: string,
    articleOptionName: string,
    availableQuantity: number,
    deliveryDescription: string,

    isTransport: boolean,

    parentBillingTypeId: number,
    parentBillingTypeName: string,

    currentExtensionAlias: string,    
    currentExtensionDescription: string,
    currentExtensionTypeId: number,
    currentExtensionTypeName: string,

    currentExtensionTypeType: DataType,
    currentExtensionTypeBaseType: BaseType,
    
    billingTypeExtensions: IBillingTypeExtension[],
    
  }

  export interface IBillingTypeExtension {
    id: number,
    billingTypeId: number,
    alias: string,
    description: string,

    extensionTypeId: number,
    extensionTypeName: string,
  
    extensionEnumerationItems: IEnumerationItem[],

    type: DataType,
    baseType: BaseType,

    enumerationCode: string
  }

  export const defaultBillingType : IBillingType = {
    id: 0,
    code: '',
    name: '',
    category: '',
    description: '',

    isRegistration: false,
    isSchoolFees: false,

    isArticle: false,

    deliveryArticleId: 0,
    articleOption: '',
    articleOptionName: '',
    availableQuantity: 0,
    deliveryDescription: '',

    isTransport: false,

    parentBillingTypeId: 0,
    parentBillingTypeName: '',

    currentExtensionAlias: '',    
    currentExtensionDescription: '',
    currentExtensionTypeId: 0,
    currentExtensionTypeName: '',

    currentExtensionTypeType: 'text',
    currentExtensionTypeBaseType: 'string',
  
    billingTypeExtensions: []
  }

  export const defaultBillingTypeExtension : IBillingTypeExtension = {
    id: 0,
    billingTypeId: 0,
    alias: '',
    description: '',

    extensionTypeId: 0,
    extensionTypeName: '',
  
    extensionEnumerationItems: [],

    type: 'text',
    baseType: 'string',

    enumerationCode: ''
  }


  export interface IBillingTypeSearch {
    
    code: string,
    name: string,
    description: string
  }