import React, { useEffect, useState } from 'react';
import './App.css';

import CssBaseline from '@mui/material/CssBaseline';
import Box  from '@mui/material/Box';
import Container from '@mui/material/Container/Container';

import {  useRecoilValue, useRecoilState } from 'recoil';




import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import { SystemMenu } from 'components/SystemMenu';
import { AppRoute } from 'components/Route';



import { LocalizationProvider } from '@mui/x-date-pickers/';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3';


import frLocale from 'date-fns/locale/fr';
import ruLocale from 'date-fns/locale/ru';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';

import { getUnixTime } from 'date-fns';

import { EntityActionDrawer } from 'components/EntityActionDrawer';
import { EntityPrintDrawer } from 'components/EntityPrintDrawer';

import { currentUserSessionAtom, isActionDrawerOpenAtom, isPrintDrawerOpenAtom, isSearchBoxShowAtom, isAuthorizationBoxShowAtom, 
  isDesktopPublishingBoxShowAtom, isExportBoxShowAtom } from 'library/store';
import { FilterBox } from 'components/FilterBox';
import { FilterFormDialog } from 'components/FilterFormDialog';
import { ExportFormDialog } from 'components/ExportFormDialog';
import { DesktopPublishingFormDialog } from 'components/DesktopPublishingFormDialog';

import { AuthorizationFormDialog } from 'components/AuthorizationFormDialog';

import { RequestNotification } from 'components/RequestNotification';
import { SessionTimeout } from 'components/SessionTimeout';
import { displayOnDesktop } from 'themes/commonStyles';

const getLocale = (lg: string) : any /*Locale*/ => {

  if(lg.includes('fr'))
    return frLocale;
  else if(lg.includes('en'))
    return enLocale;

  return frLocale;
}

function App() {

  const {isAuthenticated, language, applicationSetup } = useRecoilValue(currentUserSessionAtom);
  const [isActionDrawerOpen, setIsActionDrawerOpen] = useRecoilState(isActionDrawerOpenAtom);
  const [isPrintDrawerOpen, setIsPrintDrawerOpen] = useRecoilState(isPrintDrawerOpenAtom);
  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);  

  const [isAuthorizationBoxShow, setIsAuthorizationBoxShow] = useRecoilState(isAuthorizationBoxShowAtom);
  
  const [isExportBoxShow, setIsExportBoxShow] = useRecoilState(isExportBoxShowAtom); 
  const [isDesktopPublishingBoxShow, setIsDesktopPublishingBoxShow] = useRecoilState(isDesktopPublishingBoxShowAtom); 

  const [dateFnsLocale, setDateFnsLocale] = useState<any>(getLocale(language));

  
  useEffect( () =>{
    
    setDateFnsLocale(getLocale(language));
    
  }, [language] );

  const boxProps = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: 100,
    overflowY: isAuthenticated?'scroll': 'hidden',  //'none',
    overflowX: 'hidden' 
  }

  return (
    <React.Fragment>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateFnsLocale}>
        <CssBaseline />
        <RequestNotification />
        <Box 
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
          }}
        >
          { isAuthenticated && <Box>
            <Header />          
          </Box> }
          { isAuthenticated && <Box>
            <SystemMenu />          
          </Box> }
          {isSearchBoxShow && <FilterFormDialog /> } 
          {isExportBoxShow && <ExportFormDialog /> }
          {isDesktopPublishingBoxShow && <DesktopPublishingFormDialog /> }
          {isAuthorizationBoxShow && <AuthorizationFormDialog /> }
          <Box
            sx={ {...boxProps} }            
          >
            <Container maxWidth="xl" disableGutters={true} sx={{ mb: 1 }}>
              <AppRoute />
              { /*isActionDrawerOpen &&*/ <EntityActionDrawer /> }
              { isPrintDrawerOpen && <EntityPrintDrawer /> }
              <Box
                sx={{ display: { xs: 'flex', md: 'none' }, }}
              >
                Kampux - {applicationSetup.companyName}
              </Box>
            </Container>
          </Box>

          { isAuthenticated && <Box sx={displayOnDesktop}>
            <Footer />
          </Box> }
          { isAuthenticated && <SessionTimeout {...{intialUnixTimeStamp: getUnixTime( (new Date())) }} />}
        </Box>
      </LocalizationProvider>
    </React.Fragment>
  );
}

export default App;
