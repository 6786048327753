import { DataType } from "features/configuration/models/ExtensionType"

export interface IBilling {
    id: number,
    registrationId: number,
    billingTypeId: number,    

    isObligatory: boolean,
    isCancelled: boolean,
    cancellationPurpose: string,
    cancellationDate: Date,    

    isTransport: boolean,
    isArticle: boolean,

    isArticleOutConfirmed: boolean,
    articleOutConfirmationDate: Date,
    //articleQuantity: number,
    deliveryArticleId: number,
    articleOption: string,
    articleOptionName: string,
    deliveryDescription: string,

    busDestinationId: number,
    isArrival: boolean,
    isDeparture: boolean,

    startDate: Date,
    endDate: Date,

    billingExtensions: IBillingExtension[],
    
    billingTypeName: string,
    billingCategory: string,
    billingCategoryName: string,
    initialAmount: number,
    amount: number,    
    amountPaid: number, 
    unpaidAmount: number, 
    dueAmount: number,
   

    firstName: string,
    lastName: string,
    schoolYearName: string,
    schoolYearClassName: string,
    registrationCode: string,

    busDestinationName: string,

    quantity: number,
    availableQuantity: number,
  }

  export interface IBillingExtension {
    id: number,
  
    billingId: number,
      
    billingTypeExtensionId: number,
   
    extensionValueId: number,
    extensionDescription: string,
    
    //extensionTypeId: number,
    type: DataType,
    enumerationCode: string,
    
    value: any
  }

  export const defaultBilling : IBilling = {
    id: 0,
    registrationId: 0,
    billingTypeId: 0,    

    isObligatory: false,
    isCancelled: false,
    cancellationPurpose: '',
    cancellationDate: new Date(),   
    
    isTransport: false,
    isArticle: false,
    isArticleOutConfirmed: false,
    articleOutConfirmationDate: new Date(),
    //articleQuantity: 0,
    deliveryArticleId: 0,
    articleOption: '',
    articleOptionName: '',
    deliveryDescription: '',

    busDestinationId: 0,
    isArrival: false,
    isDeparture: false,

    startDate: new Date(),
    endDate: new Date(),

    billingExtensions: [],
    
    billingTypeName: '',
    billingCategory: '',
    billingCategoryName: '',
    initialAmount: 0,    
    amount: 0,    
    amountPaid: 0, 
    unpaidAmount: 0, 
    dueAmount: 0,

    firstName: '',
    lastName: '',
    schoolYearName: '',
    schoolYearClassName: '',

    registrationCode: '',
    busDestinationName: '',

    quantity: 0,
    availableQuantity: 0
  }


  export interface IBillingSearch {
    schoolYearId: number,
        
    schoolYearClassName: string,

    firstName: string,
    lastName: string,

  }

